import React, { useState,useMemo } from "react";
import FilterComponent from './FilterComponent';
import DataTable from 'react-data-table-component';



const ExpandedComponent = (data:any) => <pre>{JSON.stringify(data, null, 2)}</pre>;


const conditionalRowStyles = [
    {
      when: (row:any) => row.title == 'Ghostbusters',
      style: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row:any) => row.Year == 1988,
      style: (row:any) => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
    },
  ];


 let Example_1 = (props:any) => {
    const data = props.data ? props.data : null;
    const columns = props.columns ? props.columns : null;
    
    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    let filteredItems = [];
    if(data){
        if(!(data.status == 404) && data instanceof Array){
            filteredItems = data.filter(
                (item:any) => item.DealerId && item.DealerId.toLowerCase().includes(filterText.toLowerCase()),
            );
        }
    }
	

	const subHeaderComponentMemo = React.useMemo(() => { 
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={(e:any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            selectableRows
            expandableRowsComponent={ExpandedComponent}
            expandableRows
            conditionalRowStyles={conditionalRowStyles}
            subHeader
            striped
            subHeaderComponent={subHeaderComponentMemo}
            pagination 
        />
    );
};

export default Example_1;