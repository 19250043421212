import React from 'react';
import Modal from 'react-modal';
import { CCarousel,CCarouselItem ,CImage,CCarouselCaption } from '@coreui/react';
import VueImg from '../../logo512.png';

const customStyles = {
  content: {
    top: '8%',
    left: '3%',
    right: 'auto',
    bottom: 'auto',
    width: '95%',
    height: '90%'

    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#modal-root');

function BasicModal(props:any) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [imgaes, setImages] = React.useState([]);

 

  function openModal() {
    setImages(props.data.split(","));
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button onClick={openModal}>Show Images</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <span style={{position:'absolute',right:'5px',top:'0px',cursor:'pointer'}} onClick={closeModal}>X</span>
        <CCarousel controls transition="crossfade" >
        {imgaes.map((name, index) => (
            <CCarouselItem>
                <CImage className="d-block w-100" height={700}  rounded  src={name} alt="slide 3" />
                <CCarouselCaption className="d-none d-md-block">
                <h5>{index} slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
                </CCarouselCaption>
            </CCarouselItem>
        ))}
            <CCarouselItem>
                <CImage className="d-block w-100" height={700}  rounded  src="https://autocount.houstondirectauto.com/Content/Upload/L00296_0_HoustonDirectAuto.jpg" alt="slide 3" />
                <CCarouselCaption className="d-none d-md-block">
                <h5>First slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
                </CCarouselCaption>
            </CCarouselItem>
        </CCarousel>
      </Modal>
    </div>
  );
}

export { BasicModal };