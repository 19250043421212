import { Button } from "baseui/button";
import { Input } from "baseui/input";
import styled from "styled-components";
import {
  HeadingXXLarge,
  HeadingXLarge,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from "baseui/typography";
import {
  Container,
  ErrorText,
  InnerContainer,
  InputWrapper,
  StyledInput,
} from "../commons";

import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login(props: any) {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const signIn = useSignIn();

  const onSubmit = async (values: any) => {
    console.log("Values: ", values);
    setError("");

    try {
      const response = await axios.post(
        "https://api.igotcars.com/auth/getToken",
        values
      );
      
      if(response.data.token){
          signIn({
            token: response.data.token,
            expiresIn: 7200,
            tokenType: "Bearer",
            authState: { email: values.EmailId },
          });
          navigate("/");
      }else{
        setError(response.data.message);
      }
      

      
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
      console.log("Error: ", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      EmailId: "",
      Password: "",
    },
    onSubmit,
  });

  return (
    <Container>
      <InnerContainer >
        <form onSubmit={formik.handleSubmit}>
          <HeadingXXLarge>Houston Direct Auto</HeadingXXLarge>
          <ErrorText>{error}</ErrorText>
          <InputWrapper>
            <StyledInput
              name="EmailId"
              value={formik.values.EmailId}
              onChange={formik.handleChange}
              placeholder="Email"
              clearOnEscape
              size="large"
              type="email"
            />
          </InputWrapper>
          <InputWrapper>
            <StyledInput
              name="Password"
              value={formik.values.Password}
              onChange={formik.handleChange}
              placeholder="Password"
              clearOnEscape
              size="large"
              type="password"
            />
          </InputWrapper>
          <InputWrapper>
            <Button size="compact" kind="primary" isLoading={formik.isSubmitting} style={{width:'40vh'}}>
              Login
            </Button>
          </InputWrapper>
        </form>
      </InnerContainer>
    </Container>
  );
}

export { Login };
