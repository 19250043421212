import { CFormSelect, CNavbar,CContainer,CNavbarBrand,CNavbarToggler,CCollapse,CNavbarNav,CNavItem,CNavLink,CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,CForm,CButton,CFormInput } from '@coreui/react';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import {useState} from 'react';

// import NavbarTop from "../navbar/navbar";

function NavbarTop(props:any) {

    const singOut = useSignOut();
    const navigate = useNavigate();

    const getInitialState = () => {
        const value = "Orange";
        return value;
      };
    
    const [entityValue, setEntityValue] = useState(getInitialState);

    const getEntity = (e:any)=>{
        setEntityValue(e.target.value);
        props.setEntity(e.target.value);
        navigate("/");
    }

    const logout = () => {
        singOut();
        navigate("/login");
      };

    return (
      <>
        <CNavbar expand="lg" colorScheme="light" className="bg-light" placement="fixed-top"  >
          <CContainer fluid>
            <CNavbarBrand href="#">Houston Direct Auto</CNavbarBrand>
            <CNavbarToggler/>
            <CCollapse className="navbar-collapse">
              <CNavbarNav>
                <CNavItem>
                    <CFormSelect aria-label="Default select example" onChange={getEntity} id="topEntity">
                        <option>Top</option>
                        <option value="Houston Direct Auto">Houston Direct Auto</option>
                        <option value="IGotCars">IGotCars</option>
                        <option value="iGOTCARS KANSAS">iGOTCARS KANSAS</option>
                        <option value="iGOTCARS Pensacola">iGOTCARS Pensacola</option>
                        <option value="USA DIRECT AUTO">USA DIRECT AUTO</option>
                    </CFormSelect>

                </CNavItem>
                <CNavItem>
                  <CNavLink href="/" active>
                    Inventory
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink href="#">Craigslist</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink href="#" >
                     Facebook Ads
                  </CNavLink>
                </CNavItem>
              </CNavbarNav>

            </CCollapse>
          </CContainer>

          <CCollapse className="navbar-collapse">
              <CNavbarNav  className="" style={{float:"right"}}  onClick={logout}>
                <CNavItem>
                  <CNavLink href="#" >
                    Logout
                  </CNavLink>
                </CNavItem> 
              </CNavbarNav>
            </CCollapse>
        </CNavbar>
      </>
    )
}

export { NavbarTop };
