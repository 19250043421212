import axios from "axios";
import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerTable } from "../commons";
import Cookies from "js-cookie";
import {NavbarTop} from "../navbar/navbar";
import {BasicModal} from "../modal/basic";
// import BasicExample from "../datatable/inventoryTable";

import Example1 from "../datatable/example1";
axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('_auth')}`;
function Home() {
  
  const [data, setData] = useState(null);
  const [columns,setColumns] = useState([{}]);
  const [topName,setTopName] = useState("Top");
   

  const getEntity = (e:any)=>{
      console.log("Raman Pass To Parent Function");
      console.log(e);
      setTopName(e);
  }

  const handleClickPost = (e:any)=>{
      alert("Waiting for cariglist API");
  }

  useEffect(()=>{
    axios.get("https://api.igotcars.com/inventory?entity="+topName, {
          withCredentials: true,
          headers: {'auth': 'Raman Kumar'}
     }).then((response)=>{
          setData(response.data);
          let columns1 = [];
          console.log(response.data[0]);
          for(let key in response.data[0]){
                if(['Id','DealerId','New_Used','ModelNumber'].includes(key)){
                    continue;
                }
               let objCol = {
                   name: key,
                   selector: (row:any) => row[key],
                   sortable: true,
               }
               columns1.push(objCol);
               if(key == 'Certified'){
                  break;
               }
          }
          
          columns1.push({
            cell:(row:any) => <BasicModal data={row.PhotoUrlList}></BasicModal>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },)

          columns1.push({
            cell:(row:any) => <button onClick={handleClickPost} >Criagslist Post</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },)

        //    columns1 = [
        //     {
        //         name: 'Id',
        //         selector: (row:any) => row.Id,
        //         sortable: true,
        //     },
        //     {
        //         name: 'DealerId',
        //         selector: (row:any) => row.DealerId,
        //         sortable: true,
        //     }
        // ];
        setColumns(columns1);
     });
  },[topName,data]);
   
 return (
    <>
      <NavbarTop setEntity={getEntity} ></NavbarTop>
      <ContainerTable >
        <br></br><br></br><br></br>
        <Example1 data={data} columns={columns}></Example1>

      </ContainerTable>
    </>
  );
}

export { Home };
